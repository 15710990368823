import React from 'react';
import { useTranslation } from 'react-i18next';

export const MakeWishTip = () => {
  const { t } = useTranslation(); // Destructure the `t` function from the hook

  return (
    <div className="flex justify-center items-center text-lg pl-16 mt-7 font-bold text-gray-800 whitespace-nowrap">
      <p className="ml-2">
        {t('leader_page.note_text')}{' '}
        <a
          href={`${window.location.origin}/make-a-wish`}
          target="_blank"
          rel="noopener noreferrer"
          style={{ textDecoration: 'underline', color: '#0000EE' }}
        >
          {t('leader_page.make_a_wish')}
        </a>
      </p>
    </div>
  );
};
