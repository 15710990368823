import React from 'react';
import { useTranslation } from "react-i18next";
import './Breadcrumbs.css'; // Import the CSS file

const Breadcrumbs = ({ sections, currentSection, setCurrentSection }) => {
  const { t } = useTranslation();
  return (
    <nav aria-label="breadcrumb" className="breadcrumb-container">
      {/* Line of text inside the Breadcrumbs component */}
      <p className="breadcrumb-header">{t("user_home.attention_header")}</p>
      <ol className="breadcrumb">
        {sections.map((section, index) => (
          <li className="breadcrumb-item" key={index} id={`breadcrumb-${section.id}`}>
            <a href={`#${section.id}`} onClick={() => setCurrentSection(section.id)}>
              {section.displayName}
            </a>
          </li>
        ))}
      </ol>
    </nav>
  );
};

export default Breadcrumbs;
