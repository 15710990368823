import { toast } from "react-toastify";
import axios from "axios";
import { base_url } from "../../components/Transformation/Transformations";
import { GET_USER_BYEMAIL } from "./user.action";

export const SET_AUTH_TOKEN = "SET_AUTH_TOKEN";
export const SET_LOG_OUT = "SET_LOG_OUT";

export const Logout = () => (dispatch) => {
  return dispatch({
    type: SET_LOG_OUT,
    payload: null,
  });
};

export const forgotPassword = (email, navigation) => (dispatch) => {
  axios
    .post(base_url + "/clubusers/forget_password", {
      username: email,
    })
    .then((response) => {
      toast.success("Password was sent to your email successfully!", {
        position: "bottom-right",
      });
      navigation("/login");
    })
    .catch((error) => {
      const message = error.response
        ? error.response.data.message
        : "An unexpected error occurred";
      toast.error(message, {
        position: "bottom-right",
      });
    });
};

export const profileRegister = ({
  age,
  degree,
  nativeLanguage,
  learningLanguage,
  level,
  school,
  recommendation,
  topic,
  navigation,
}) => (dispatch) => {
  axios
    .post(base_url + "/clubusers/", {
      age,
      degree,
      nativeLanguage,
      learningLanguage,
      level,
      school,
      recommendation,
      topic,
    })
    .then((response) => {
      if (response.status === 201) {
        toast.success("Successfully registered !", {
          position: "bottom-right",
        });
      }
    })
    .catch((error) => {
      const message = error.response
        ? error.response.data.status
        : "An unexpected error occurred";
      toast.error(message, {
        position: "bottom-right",
      });
    });
};

export const register = ({
  firstName,
  lastName,
  email,
  password,
  confirmPassword,
  timeZone,
  navigation,
}) => (dispatch) => {
  return axios
    .post(base_url + "/clubusers/", {
      first_name: firstName,
      last_name: lastName,
      email,
      preferred_name: firstName,
      password,
      confirm_password: confirmPassword,
      timezone: timeZone,
    }, {
      withCredentials: true
    })
    .then((response) => {
      if (response.status === 201) {
        toast.success("Successfully registered!", {
          position: "bottom-right",
        });

        // Dispatch login action and return the status
        return dispatch(login({ email, password, navigation }));
      }
      return { status: response.status }; // Return status for other cases
    })
    .catch((error) => {
      const status = error.response ? error.response.status : 500;
      return { status }; // Return the status
    });
};


export const login = ({ email, password, navigation }) => (dispatch) => {
  return axios
    .post(base_url + "/clubusers/login", {
      username: email,
      password,
    })
    .then(async (response) => {
      const status = response.status;  // Capture HTTP status

      toast.success("You have logged in successfully.", {
        position: "bottom-right",
      });

      const token = response.data.token;
      const mail = response.data.user;

      localStorage.setItem("token", token);
      localStorage.setItem("mail", mail);

      await dispatch({
        type: SET_AUTH_TOKEN,
        payload: response.data,
      });

      // Fetch user by email
      return axios
        .get(`${base_url}/clubusers/?email=${mail}`, {
          headers: {
            Authorization: "Token " + token,
          },
        })
        .then((userResponse) => {
          const userData = userResponse.data.results[0];
          localStorage.setItem("userData", JSON.stringify(userData));

          dispatch({
            type: GET_USER_BYEMAIL,
            payload: userData,
          });

          navigation("/user/profile");

          return { status, data: response.data }; // Return the status and data
        })
        .catch((error) => {
          const message = error.response
            ? error.response.data.status
            : "An unexpected error occurred";
          toast.error(message, {
            position: "bottom-right",
          });

          return { status: error.response ? error.response.status : 500 }; // Return error status
        });
    })
    .catch((error) => {
      const status = error.response ? error.response.status : 500;
      return { status }; // Return the error status
    });
};

