import React, { useEffect, useRef } from 'react';
import QRCode from 'qrcode';

const QRCodeGenerator = ({ url, width }) => {
  const canvasRef = useRef(null);

  useEffect(() => {
    if (url) {
      QRCode.toCanvas(canvasRef.current, url, { width: width, margin: 1 }, (error) => {
        if (error) console.error(error);
      });
    }
  }, [url, width]);

  return (
    <div style={{ display: 'flex', justifyContent: 'center', marginTop: '0px' }}>
      <canvas
        ref={canvasRef}
        style={{ display: 'block', margin: '0', padding: '0' }} // Ensure no extra space
      ></canvas>
    </div>
  );
};

export default QRCodeGenerator;
